/**
 * Generated by orval v7.1.1 🍺
 * Do not edit manually.
 * MattaOS
 * OpenAPI spec version: 2024-12-04T16:25:06
 */
import type {
  BodyImageItemsCreateImageItemWithPrintJob,
  BodyImageItemsCreateImageItemWithPrintJobPredict,
  ImageItem,
  ImageItemCreate,
  ImageItemResponse,
  ImageItemUpdate,
  ImageItemsCountImageItemsDateRangeParams,
  ImageItemsReadImageItemsParams,
  ImageItemsReadPrintJobImageItemsAdminParams,
  ImageItemsReadPrintJobImageItemsParams,
} from "../api-generated.schemas";
import { apiAxiosInstance } from "../../api-client";

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * Retrieve image_items.
 * @summary Read Image Items
 */
export const imageItemsReadImageItems = (
  params?: ImageItemsReadImageItemsParams,
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  return apiAxiosInstance<ImageItem[]>(
    { url: `/api/v1/images/`, method: "GET", params },
    options,
  );
};
/**
 * Create new image_item.
 * @summary Create Image Item
 */
export const imageItemsCreateImageItem = (
  imageItemCreate: ImageItemCreate,
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  return apiAxiosInstance<ImageItem>(
    {
      url: `/api/v1/images/`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: imageItemCreate,
    },
    options,
  );
};
/**
 * Retrieve image_items.
 * @summary Read Print Job Image Items
 */
export const imageItemsReadPrintJobImageItems = (
  printJobId: number,
  params?: ImageItemsReadPrintJobImageItemsParams,
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  return apiAxiosInstance<ImageItemResponse[]>(
    { url: `/api/v1/images/print/${printJobId}`, method: "GET", params },
    options,
  );
};
/**
 * Retrieve image_items.
 * @summary Read Print Job Image Items Admin
 */
export const imageItemsReadPrintJobImageItemsAdmin = (
  printJobId: number,
  params?: ImageItemsReadPrintJobImageItemsAdminParams,
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  return apiAxiosInstance<ImageItemResponse[]>(
    { url: `/api/v1/images/print/${printJobId}/admin`, method: "GET", params },
    options,
  );
};
/**
 * Count image_items.
 * @summary Count Image Items
 */
export const imageItemsCountImageItems = (
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  return apiAxiosInstance<number>(
    { url: `/api/v1/images/count`, method: "GET" },
    options,
  );
};
/**
 * Count image_items.
 * @summary Count Image Items All
 */
export const imageItemsCountImageItemsAll = (
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  return apiAxiosInstance<number>(
    { url: `/api/v1/images/count/all`, method: "GET" },
    options,
  );
};
/**
 * Count image_items between two dates.

start_date: str format YYYY-MM-DD
end_date: str format YYYY-MM-DD
 * @summary Count Image Items Date Range
 */
export const imageItemsCountImageItemsDateRange = (
  params: ImageItemsCountImageItemsDateRangeParams,
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  return apiAxiosInstance<number>(
    { url: `/api/v1/images/count/date-range`, method: "GET", params },
    options,
  );
};
/**
 * Count image_items.
 * @summary Count Print Job Image Items
 */
export const imageItemsCountPrintJobImageItems = (
  printJobId: number,
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  return apiAxiosInstance<number>(
    { url: `/api/v1/images/print/${printJobId}/count`, method: "GET" },
    options,
  );
};
/**
 * Update a image_item.
 * @summary Update Item
 */
export const imageItemsUpdateItem = (
  id: number,
  imageItemUpdate: ImageItemUpdate,
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  return apiAxiosInstance<ImageItem>(
    {
      url: `/api/v1/images/${id}`,
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      data: imageItemUpdate,
    },
    options,
  );
};
/**
 * Get image_item by ID.
 * @summary Read Image Item
 */
export const imageItemsReadImageItem = (
  id: number,
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  return apiAxiosInstance<ImageItem>(
    { url: `/api/v1/images/${id}`, method: "GET" },
    options,
  );
};
/**
 * Delete a image_item.
 * @summary Delete Image Item
 */
export const imageItemsDeleteImageItem = (
  id: number,
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  return apiAxiosInstance<ImageItem>(
    { url: `/api/v1/images/${id}`, method: "DELETE" },
    options,
  );
};
/**
 * Get image_item by from print_job by count.
 * @summary Read Print Job Image Item
 */
export const imageItemsReadPrintJobImageItem = (
  printJobId: number,
  imageCount: number,
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  return apiAxiosInstance<ImageItemResponse>(
    { url: `/api/v1/images/print/${printJobId}/image/${imageCount}`, method: "GET" },
    options,
  );
};
/**
 * Get image_item by ID.
 * @summary Read Print Job Middle Image Item
 */
export const imageItemsReadPrintJobMiddleImageItem = (
  printJobId: number,
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  return apiAxiosInstance<ImageItemResponse>(
    { url: `/api/v1/images/print/${printJobId}/middle`, method: "GET" },
    options,
  );
};
/**
 * Create new image_item.
 * @summary Create Image Item With Print Job
 */
export const imageItemsCreateImageItemWithPrintJob = (
  bodyImageItemsCreateImageItemWithPrintJob: BodyImageItemsCreateImageItemWithPrintJob,
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  const formData = new FormData();
  formData.append("image_obj", bodyImageItemsCreateImageItemWithPrintJob.image_obj);
  formData.append("data", bodyImageItemsCreateImageItemWithPrintJob.data);

  return apiAxiosInstance<ImageItem>(
    {
      url: `/api/v1/images/print/upload/new-image`,
      method: "POST",
      headers: { "Content-Type": "multipart/form-data" },
      data: formData,
    },
    options,
  );
};
/**
 * Create new image_item.
 * @summary Create Image Item With Print Job Predict
 */
export const imageItemsCreateImageItemWithPrintJobPredict = (
  bodyImageItemsCreateImageItemWithPrintJobPredict: BodyImageItemsCreateImageItemWithPrintJobPredict,
  options?: SecondParameter<typeof apiAxiosInstance>,
) => {
  const formData = new FormData();
  formData.append(
    "image_obj",
    bodyImageItemsCreateImageItemWithPrintJobPredict.image_obj,
  );
  formData.append("data", bodyImageItemsCreateImageItemWithPrintJobPredict.data);

  return apiAxiosInstance<ImageItem>(
    {
      url: `/api/v1/images/print/predict/new-image`,
      method: "POST",
      headers: { "Content-Type": "multipart/form-data" },
      data: formData,
    },
    options,
  );
};
export type ImageItemsReadImageItemsResult = NonNullable<
  Awaited<ReturnType<typeof imageItemsReadImageItems>>
>;
export type ImageItemsCreateImageItemResult = NonNullable<
  Awaited<ReturnType<typeof imageItemsCreateImageItem>>
>;
export type ImageItemsReadPrintJobImageItemsResult = NonNullable<
  Awaited<ReturnType<typeof imageItemsReadPrintJobImageItems>>
>;
export type ImageItemsReadPrintJobImageItemsAdminResult = NonNullable<
  Awaited<ReturnType<typeof imageItemsReadPrintJobImageItemsAdmin>>
>;
export type ImageItemsCountImageItemsResult = NonNullable<
  Awaited<ReturnType<typeof imageItemsCountImageItems>>
>;
export type ImageItemsCountImageItemsAllResult = NonNullable<
  Awaited<ReturnType<typeof imageItemsCountImageItemsAll>>
>;
export type ImageItemsCountImageItemsDateRangeResult = NonNullable<
  Awaited<ReturnType<typeof imageItemsCountImageItemsDateRange>>
>;
export type ImageItemsCountPrintJobImageItemsResult = NonNullable<
  Awaited<ReturnType<typeof imageItemsCountPrintJobImageItems>>
>;
export type ImageItemsUpdateItemResult = NonNullable<
  Awaited<ReturnType<typeof imageItemsUpdateItem>>
>;
export type ImageItemsReadImageItemResult = NonNullable<
  Awaited<ReturnType<typeof imageItemsReadImageItem>>
>;
export type ImageItemsDeleteImageItemResult = NonNullable<
  Awaited<ReturnType<typeof imageItemsDeleteImageItem>>
>;
export type ImageItemsReadPrintJobImageItemResult = NonNullable<
  Awaited<ReturnType<typeof imageItemsReadPrintJobImageItem>>
>;
export type ImageItemsReadPrintJobMiddleImageItemResult = NonNullable<
  Awaited<ReturnType<typeof imageItemsReadPrintJobMiddleImageItem>>
>;
export type ImageItemsCreateImageItemWithPrintJobResult = NonNullable<
  Awaited<ReturnType<typeof imageItemsCreateImageItemWithPrintJob>>
>;
export type ImageItemsCreateImageItemWithPrintJobPredictResult = NonNullable<
  Awaited<ReturnType<typeof imageItemsCreateImageItemWithPrintJobPredict>>
>;
