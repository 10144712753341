import { mutations } from "./mutations";
import { getters } from "./getters";
import { actions } from "./actions";
import { AdminState } from "./state";

const defaultState: AdminState = {
  adminUsers: [],
  adminPrinters: [],
  adminPrintJobs: [],
  adminCurrentlyViewedPrintJob: null,
  adminImageItems: [],
  adminImageItemCount: 0,
  adminStripeProducts: [],
  adminStripeSubscriptions: [],
  adminStripePricings: [],
  adminAnnouncements: [],
};

export const adminModule = {
  state: defaultState,
  mutations,
  actions,
  getters,
};
