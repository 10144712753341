import {
  IAppAlert,
  IMaterialCreate,
  IPrinterMacroCreate,
  IPrintJobUpdate,
} from "@/interfaces";
import router from "@/router";
import { getLocalToken, removeLocalToken, saveLocalToken } from "@/utils";
import axios from "axios";
import { getStoreAccessors } from "typesafe-vuex";
import { ActionContext } from "vuex";
import { State } from "../state";
import {
  commitAddAlert,
  commitDeleteTag,
  commitRemoveAlert,
  commitSetAnnouncements,
  commitSetErrorEvents,
  commitSetFile,
  commitSetFiles,
  commitSetImageItemCount,
  commitSetImageItems,
  commitSetLoggedIn,
  commitSetLogInError,
  commitSetMacro,
  commitSetMacros,
  commitSetMaterial,
  commitSetMaterials,
  commitSetNotifications,
  commitSetOriginalURL,
  commitSetPluginVersions,
  commitSetPricings,
  commitSetPrinter,
  commitSetPrinters,
  commitSetPrinterSocket,
  commitSetPrinterSockets,
  commitSetPrintJob,
  commitSetPrintJobs,
  commitSetProducts,
  commitSetSignUpError,
  commitSetTag,
  commitSetTags,
  commitSetTask,
  commitSetToken,
  commitSetUserProfile,
  commitSetUserSocket,
  commitSetUserSubscriptions,
} from "./mutations";
import { MainState } from "./state";
import {
  usersCreateUserOpen,
  usersReadUserMe,
  usersUpdateUserMe,
} from "@/api-generated/users/users";
import {
  type BodyFilesCreateFile,
  type BodyPrintJobsUploadGcodeFromBrowser,
  BodyUsersCreateUserOpen,
  type CreateCustomerPortalSessionRequest,
  type Directory,
  FileUpdate,
  type MacroCreate,
  type MacroUpdate,
  type Notification,
  type PrinterCreate,
  type PrinterUpdate,
  type PrintJob,
  type PrintJobDownload,
  StripePricing,
  TagCreate,
  TagUpdate,
  type TaskIds,
  type UserUpdate,
} from "@/api-generated/api-generated.schemas";
import {
  loginLoginAccessToken,
  loginRecoverPassword,
  loginResetPassword,
} from "@/api-generated/login/login";
import {
  printJobsAnalysePrintJob,
  printJobsCreateCaxtonHeatmap,
  printJobsCreatePrintJobZip,
  printJobsDeletePrintJob,
  printJobsDownloadPrintJob,
  printJobsGetSuccessRate,
  printJobsReadPrintJobCsv,
  printJobsReadPrintJobGcode,
  printJobsReadPrintJobsPaginated,
  printJobsUpdateItem,
  printJobsUploadGcodeFromBrowser,
} from "@/api-generated/print-jobs/print-jobs";
import { eventlogReadErrorEvents } from "@/api-generated/eventlog/eventlog";
import {
  imageItemsCountImageItems,
  imageItemsCountImageItemsDateRange,
  imageItemsCountPrintJobImageItems,
  imageItemsReadPrintJobImageItem,
  imageItemsReadPrintJobImageItems,
  imageItemsReadPrintJobMiddleImageItem,
} from "@/api-generated/image-items/image-items";
import {
  filesCreateDirectory,
  filesCreateFile,
  filesDeleteAllFiles,
  filesDeleteFile,
  filesReadFilesByParent,
  filesUpdateFile,
} from "@/api-generated/files/files";
import { authHeaders } from "@/api-client";
import {
  printersCreatePrinter,
  printersDeletePrinter,
  printersReadPrinter,
  printersReadPrinters,
  printersUpdatePrinter,
} from "@/api-generated/printers/printers";
import {
  printerTagsCreatePrinterTag,
  printerTagsDeletePrinterTag,
} from "@/api-generated/printer-tags/printer-tags";
import {
  notificationsDeleteNotification,
  notificationsReadNotifications,
  notificationsSetViewedNotifications,
} from "@/api-generated/notifications/notifications";
import { announcementsReadCurrentAnnouncement } from "@/api-generated/announcements/announcements";
import { productsReadStripeProducts } from "@/api-generated/products/products";
import {
  pricingsCreateCheckoutSession,
  pricingsReadStripePricings,
} from "@/api-generated/pricings/pricings";
import {
  subscriptionsCreateCustomerPortalSession,
  subscriptionsReadStripeSubscriptions,
} from "@/api-generated/subscriptions/subscriptions";
import {
  macrosCreateMacro,
  macrosCreatePrinterMacro,
  macrosDeleteMacro,
  macrosDeletePrinterMacro,
  macrosReadMacro,
  macrosReadPrinterMacros,
  macrosUpdateMacro,
} from "@/api-generated/macros/macros";
import {
  materialsCreateMaterial,
  materialsDeleteMaterial,
  materialsReadMaterials,
  materialsUpdateMaterial,
} from "@/api-generated/materials/materials";
import {
  tagsCreateTag,
  tagsDeleteTag,
  tagsReadTag,
  tagsReadTags,
  tagsUpdateTag,
} from "@/api-generated/tags/tags";
import {
  utilsGetAllTaskIdsByOwner,
  utilsGetPluginVersion,
  utilsPostSyncStatus,
} from "@/api-generated/utils/utils";

type MainContext = ActionContext<MainState, State>;

export const actions = {
  async actionCreateNewUser(context: MainContext, payload: BodyUsersCreateUserOpen) {
    try {
      const response = await usersCreateUserOpen(payload);
      if (response && payload.email && payload.password) {
        // get error message from response
        await dispatchLogIn(context, {
          username: payload.email,
          password: payload.password,
        });
      }
      commitSetSignUpError(context, false);
    } catch (error) {
      // TODO: propagate the message to the frontend.
      commitSetSignUpError(context, true);
      await dispatchCheckApiError(context, error);
    }
  },
  async actionLogIn(
    context: MainContext,
    payload: { username: string; password: string; originalURL?: string },
  ) {
    try {
      const response = await loginLoginAccessToken({
        username: payload.username,
        password: payload.password,
      });
      const token = response.access_token;
      if (token) {
        sessionStorage.clear();
        saveLocalToken(token);
        commitSetToken(context, token);
        commitSetLoggedIn(context, true);
        commitSetLogInError(context, false);
        await dispatchGetUserProfile(context);
        await dispatchRouteLoggedIn(context, payload.originalURL);
        commitAddAlert(context, { content: "Logged in", color: "success" });
      } else {
        await dispatchLogOut(context);
      }
    } catch (err) {
      commitSetLogInError(context, true);
      await dispatchLogOut(context);
    }
  },
  async actionGetUserProfile(context: MainContext) {
    try {
      const response = await usersReadUserMe(context.state.token);
      if (response) {
        commitSetUserProfile(context, response);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionGetUserWebsocket(context: MainContext) {
    try {
      const response = await usersReadUserMe(context.state.token);
      if (response) {
        await commitSetUserSocket(context.state, response.auth_key);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionUpdateUserProfile(context: MainContext, payload: UserUpdate) {
    try {
      const loadingAlert = { content: "Saving", showProgress: true };
      commitAddAlert(context, loadingAlert);
      const response = (
        await Promise.all([
          usersUpdateUserMe(payload, context.state.token),
          new Promise<void>((resolve, _) => setTimeout(() => resolve(), 500)),
        ])
      )[0];
      commitSetUserProfile(context, response);
      commitRemoveAlert(context, loadingAlert);
      commitAddAlert(context, {
        content: "Profile successfully updated",
        color: "success",
      });
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionCheckLoggedIn(context: MainContext) {
    if (!context.state.isLoggedIn) {
      let token = context.state.token;
      if (!token) {
        const localToken = getLocalToken();
        if (localToken) {
          commitSetToken(context, localToken);
          token = localToken;
        }
      }
      if (token) {
        try {
          const response = await usersReadUserMe(context.state.token);
          commitSetLoggedIn(context, true);
          commitSetUserProfile(context, response);
        } catch (error) {
          await dispatchRemoveLogIn(context);
        }
      } else {
        await dispatchRemoveLogIn(context);
      }
    }
  },
  async actionRemoveLogIn(context: MainContext) {
    removeLocalToken();
    commitSetToken(context, "");
    commitSetLoggedIn(context, false);
    try {
      commitSetPrinters(context, []);
    } catch (error) {
      console.error(error);
    }
  },
  async actionLogOut(context: MainContext) {
    await dispatchRemoveLogIn(context);
    await dispatchRouteLogOut(context);
  },
  async actionUserLogOut(context: MainContext) {
    await dispatchLogOut(context);
    commitAddAlert(context, { content: "Logged out", color: "success" });
  },
  actionRouteLogOut() {
    if (router.currentRoute.path !== "/login") {
      router.push("/login");
    }
  },
  async actionCheckApiError(context: MainContext, payload: unknown) {
    if (axios.isAxiosError(payload)) {
      if (payload.response?.status === 401) {
        await dispatchLogOut(context);
      }
    }
  },
  actionRouteLoggedIn(context: MainContext, originalURL: string | undefined) {
    if (originalURL && originalURL !== "") {
      router.push(originalURL).catch(() => {});
      commitSetOriginalURL(context, "");
      return;
    }
    if (
      router.currentRoute.path === "/login" ||
      router.currentRoute.path === "/" ||
      router.currentRoute.path === "/signup"
    ) {
      router.push("/main/printers/view");
    }
  },
  async removeAlert(
    context: MainContext,
    payload: { alert: IAppAlert; timeout: number },
  ) {
    return new Promise((resolve, _) => {
      setTimeout(() => {
        commitRemoveAlert(context, payload.alert);
        resolve(true);
      }, payload.timeout);
    });
  },
  async passwordRecovery(context: MainContext, payload: { username: string }) {
    const loadingAlert = {
      content: "Sending password recovery email",
      showProgress: true,
    };
    try {
      commitAddAlert(context, loadingAlert);
      await Promise.all([
        loginRecoverPassword(payload.username),
        new Promise<void>((resolve, _) => setTimeout(() => resolve(), 500)),
      ]);
      commitRemoveAlert(context, loadingAlert);
      commitAddAlert(context, {
        content: "Password recovery email sent",
        color: "success",
      });
      await dispatchLogOut(context);
    } catch (error) {
      commitRemoveAlert(context, loadingAlert);
      commitAddAlert(context, { color: "error", content: "Incorrect username" });
    }
  },
  async resetPassword(
    context: MainContext,
    payload: { password: string; token: string },
  ) {
    const loadingAlert = { content: "Resetting password", showProgress: true };
    try {
      commitAddAlert(context, loadingAlert);
      await Promise.all([
        loginResetPassword({ new_password: payload.password, token: payload.token }),
        new Promise<void>((resolve, _) => setTimeout(() => resolve(), 500)),
      ]);
      commitRemoveAlert(context, loadingAlert);
      commitAddAlert(context, {
        content: "Password successfully reset",
        color: "success",
      });
      await dispatchLogOut(context);
    } catch (error) {
      commitRemoveAlert(context, loadingAlert);
      commitAddAlert(context, {
        color: "error",
        content: "Error resetting password",
      });
    }
  },
  async actionGetPrintJobs(context: MainContext) {
    try {
      const response = await printJobsReadPrintJobsPaginated({}, context.state.token);
      if (response) {
        commitSetPrintJobs(context, response);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionGetPrintJobSuccessRate(context: MainContext) {
    try {
      const response = await printJobsGetSuccessRate(context.state.token);
      return response; // TODO: commitSetPrintJobs the success rate?
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionUpdatePrintJob(
    context: MainContext,
    payload: { id: number; printJob: IPrintJobUpdate },
  ) {
    try {
      const response = (
        await Promise.all([
          printJobsUpdateItem(payload.id, payload.printJob, context.state.token),
          new Promise<void>((resolve, _) => setTimeout(() => resolve(), 500)),
        ])
      )[0];
      commitSetPrintJob(context, response);
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionPostHeatmap(
    context: MainContext,
    payload: { data: PrintJob; rerun?: boolean },
  ) {
    try {
      const response = await printJobsCreateCaxtonHeatmap(
        payload.data,
        { rerun: payload.rerun },
        context.state.token,
      );
      if (response) {
        return response;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionGetErrorEvents(context: MainContext) {
    try {
      const response = await eventlogReadErrorEvents({}, context.state.token);
      if (response) {
        commitSetErrorEvents(context, response);
        return response;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionGetImageItems(context: MainContext, payload: number) {
    try {
      const response = await imageItemsReadPrintJobImageItems(
        payload,
        {},
        context.state.token,
      );
      if (response) {
        commitSetImageItems(context, response);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionGetImageItemCount(context: MainContext) {
    try {
      const response = await imageItemsCountImageItems(context.state.token);
      if (response !== undefined) {
        commitSetImageItemCount(context, response);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionGetImageItemCountByDateRange(
    context: MainContext,
    payload: {
      start_date: string; // note: this is a str in the format YYYY-MM-DD
      end_date: string; // note: this is a str in the format YYYY-MM-DD
    },
  ) {
    try {
      const response = await imageItemsCountImageItemsDateRange(
        payload,
        context.state.token,
      );
      if (response !== undefined) {
        commitSetImageItemCount(context, response);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionGetPrintJobImageItemCount(context: MainContext, payload: number) {
    try {
      const response = await imageItemsCountPrintJobImageItems(
        payload,
        context.state.token,
      );
      if (response !== undefined) {
        return response;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionPostPrintJobZip(
    context: MainContext,
    payload: { systemId: number; zip_file: Blob },
  ) {
    try {
      const response = await printJobsCreatePrintJobZip(
        payload.systemId,
        { zip_file: payload.zip_file },
        context.state.token,
      );
      if (response) {
        return response;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionPostGcodeUpload(
    context: MainContext,
    payload: BodyPrintJobsUploadGcodeFromBrowser,
  ) {
    try {
      const loadingAlert = { content: "Uploading gcode...", showProgress: true };
      commitAddAlert(context, loadingAlert);
      const response = await printJobsUploadGcodeFromBrowser(
        payload,
        context.state.token,
      );
      if (response) {
        return response;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionGetPrintJobMiddleImageItem(context: MainContext, id: number) {
    try {
      const response = await imageItemsReadPrintJobMiddleImageItem(
        id,
        context.state.token,
      );
      if (response) {
        return response;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionGetPrintJobImageItem(
    context: MainContext,
    payload: { print_job_id: number; image_item_count: number },
  ) {
    try {
      const response = await imageItemsReadPrintJobImageItem(
        payload.print_job_id,
        payload.image_item_count,
        context.state.token,
      );
      if (response) {
        return response;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionGetFiles(context: MainContext, payload: number) {
    try {
      const response = await filesReadFilesByParent(payload, {}, context.state.token);
      if (response) {
        commitSetFiles(context, response);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionPostFile(context: MainContext, payload: BodyFilesCreateFile) {
    const loadingAlert = { content: "Uploading file...", showProgress: true };
    try {
      commitAddAlert(context, loadingAlert);
      const response = await filesCreateFile(payload, context.state.token);
      if (response) {
        commitRemoveAlert(context, loadingAlert);
        return response;
      }
    } catch (error: any) {
      // get the response error message from the error
      commitRemoveAlert(context, loadingAlert);
      const msg: string = error.response.detail;
      commitAddAlert(context, { content: msg, color: "error" });
      await dispatchCheckApiError(context, error);
    }
  },
  async actionUpdateFile(
    context: MainContext,
    payload: { id: number; file: FileUpdate },
  ) {
    try {
      const response = await filesUpdateFile(
        payload.id,
        payload.file,
        context.state.token,
      );
      if (response) {
        commitSetFile(context, response);
        return response;
      }
    } catch (error: any) {
      const msg: string = error.response.detail;
      commitAddAlert(context, { content: msg, color: "error" });
      await dispatchCheckApiError(context, error);
      await dispatchCheckApiError(context, error);
    }
  },

  async actionDeleteFile(context: MainContext, id: number) {
    try {
      const loadingAlert = { content: "Deleting...", showProgress: true };
      commitAddAlert(context, loadingAlert);
      const response = await filesDeleteFile(id, context.state.token);
      if (response) {
        commitRemoveAlert(context, loadingAlert);
        return response;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionDeleteAllFiles(context: MainContext) {
    try {
      const loadingAlert = { content: "Deleting...", showProgress: true };
      commitAddAlert(context, loadingAlert);
      const response = await filesDeleteAllFiles(context.state.token);
      if (response) {
        commitRemoveAlert(context, loadingAlert);
        return response;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionPostMakeDirectory(context: MainContext, payload: Directory) {
    try {
      const response = await filesCreateDirectory(payload, context.state.token);
      if (response) {
        commitSetFile(context, response);
        return response;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionGetPrintJobGcode(context: MainContext, id: number) {
    try {
      const response = await printJobsReadPrintJobGcode(id, context.state.token);
      if (response) {
        return response;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionGetPrintJobCSV(context: MainContext, id: number) {
    try {
      const response = await printJobsReadPrintJobCsv(id, context.state.token);
      if (response) {
        return response;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionGetPrintJobData(context: MainContext, id) {
    try {
      const response: any = await printJobsDownloadPrintJob(id, {
        ...authHeaders(context.state.token),
        ...{ responseType: "blob" },
      }); // above is actually a Blob
      return (await JSON.parse(await response.text())) as PrintJobDownload;
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionPostAnalysePrintJob(context: MainContext, payload: PrintJob) {
    try {
      const response = await printJobsAnalysePrintJob(payload, context.state.token);
      if (response) {
        return response;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionDeletePrintJob(context: MainContext, id: number) {
    try {
      const loadingAlert = { content: "Deleting...", showProgress: true };
      commitAddAlert(context, loadingAlert);
      const response = await printJobsDeletePrintJob(id, context.state.token);
      if (response) {
        commitRemoveAlert(context, loadingAlert);
        return response;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionGetPrinters(context: MainContext) {
    try {
      const response = await printersReadPrinters({}, context.state.token);
      if (response) {
        commitSetPrinters(context, response);
        await commitSetPrinterSockets(context.state);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionGetPrinter(context: MainContext, id: number) {
    try {
      const response = await printersReadPrinter(id, context.state.token);
      if (response) {
        commitSetPrinter(context, response);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionUpdatePrinter(
    context: MainContext,
    payload: { id: number; printer: PrinterUpdate },
  ) {
    try {
      const loadingAlert = { content: "Saving", showProgress: true };
      commitAddAlert(context, loadingAlert);
      const response = (
        await Promise.all([
          printersUpdatePrinter(payload.id, payload.printer, context.state.token),
          new Promise<void>((resolve, _) => setTimeout(() => resolve(), 500)),
        ])
      )[0];
      commitSetPrinter(context, response);
      commitRemoveAlert(context, loadingAlert);
      commitAddAlert(context, {
        content: "Printer successfully updated",
        color: "success",
      });
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionCreatePrinter(context: MainContext, payload: PrinterCreate) {
    try {
      const loadingAlert = { content: "Saving", showProgress: true };
      commitAddAlert(context, loadingAlert);
      const response = (
        await Promise.all([
          printersCreatePrinter(payload, context.state.token),
          new Promise<void>((resolve, _) => setTimeout(() => resolve(), 500)),
        ])
      )[0];
      commitSetPrinter(context, response);
      commitRemoveAlert(context, loadingAlert);
      commitAddAlert(context, {
        content: "Printer successfully created",
        color: "success",
      });
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionDeletePrinter(context: MainContext, id: number) {
    try {
      const loadingAlert = { content: "Deleting...", showProgress: true };
      commitAddAlert(context, loadingAlert);
      const response = await printersDeletePrinter(id, context.state.token);
      if (response) {
        commitRemoveAlert(context, loadingAlert);
        return response;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionCreatePrinterTag(
    context: MainContext,
    payload: { printer_id: number; tag_id: number },
  ) {
    try {
      const response = await printerTagsCreatePrinterTag(payload, context.state.token);
      if (response) {
        return response;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionDeletePrinterTag(context: MainContext, id: number) {
    try {
      const response = await printerTagsDeletePrinterTag(id, context.state.token);
      if (response) {
        return response;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionGetPrinterSocket(context: MainContext, payload) {
    try {
      await commitSetPrinterSocket(context.state, payload);
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionGetNotifications(context: MainContext) {
    try {
      const response = await notificationsReadNotifications({}, context.state.token);
      if (response) {
        commitSetNotifications(context, response);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionMarkViewedNotifications(context: MainContext, payload: Notification[]) {
    try {
      await notificationsSetViewedNotifications(payload, context.state.token);
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionDismissNotifications(context: MainContext, payload: Notification[]) {
    try {
      await notificationsDeleteNotification(payload, context.state.token);
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionGetAnnouncements(context: MainContext) {
    try {
      const response = await announcementsReadCurrentAnnouncement(
        {},
        context.state.token,
      );
      if (response) {
        commitSetAnnouncements(context, response);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionGetProducts(context: MainContext) {
    try {
      const response = await productsReadStripeProducts(context.state.token);
      if (response) {
        commitSetProducts(context, response);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionGetPricings(context: MainContext) {
    try {
      const response = await pricingsReadStripePricings(context.state.token);
      if (response) {
        commitSetPricings(context, response);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionGetUserSubscriptions(context: MainContext) {
    try {
      const response = await subscriptionsReadStripeSubscriptions(context.state.token);
      if (response) {
        commitSetUserSubscriptions(context, response);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionCreateCheckoutSession(context: MainContext, payload: StripePricing) {
    try {
      // Clone the data object and add success_url and cancel_url properties
      const requestData = {
        ...payload,
        success_url: `${window.location.origin}/main/printers/view`,
        cancel_url: `${window.location.origin}/main/products/view`,
      };
      const response = await pricingsCreateCheckoutSession(
        requestData,
        context.state.token,
      );
      if (response) {
        window.location.href = response.url;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionCreateCustomerPortalSession(
    context: MainContext,
    payload: CreateCustomerPortalSessionRequest,
  ) {
    try {
      const response = await subscriptionsCreateCustomerPortalSession(
        payload,
        context.state.token,
      );
      if (response) {
        window.location.href = response.url;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  // Macros!
  async actionGetMacros(context: MainContext) {
    // watch this
    try {
      const response = await macrosReadPrinterMacros({}, context.state.token);
      if (response) {
        commitSetMacros(context, response);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionGetMacro(context: MainContext, id: number) {
    try {
      const response = await macrosReadMacro(id, context.state.token);
      if (response) {
        return response;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionUpdateMacro(
    context: MainContext,
    payload: { id: number; macro: MacroUpdate },
  ) {
    try {
      const loadingAlert = { content: "Saving", showProgress: true };
      commitAddAlert(context, loadingAlert);
      const response = (
        await Promise.all([
          macrosUpdateMacro(payload.id, payload.macro, context.state.token),
          new Promise<void>((resolve, _) => setTimeout(() => resolve(), 500)),
        ])
      )[0];
      commitSetMacro(context, response);
      commitRemoveAlert(context, loadingAlert);
      commitAddAlert(context, {
        content: "Macro successfully updated",
        color: "success",
      });
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionCreateMacro(context: MainContext, payload: MacroCreate) {
    try {
      const loadingAlert = { content: "Saving", showProgress: true };
      commitAddAlert(context, loadingAlert);
      const response = (
        await Promise.all([
          macrosCreateMacro(payload, context.state.token),
          new Promise<void>((resolve, _) => setTimeout(() => resolve(), 500)),
        ])
      )[0];
      commitSetMacro(context, response);
      commitRemoveAlert(context, loadingAlert);
      commitAddAlert(context, {
        content: "Macro successfully created",
        color: "success",
      });
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionCreatePrinterMacro(context: MainContext, payload: IPrinterMacroCreate) {
    try {
      const response = (
        await Promise.all([
          macrosCreatePrinterMacro(payload, context.state.token),
          new Promise<void>((resolve, _) => setTimeout(() => resolve(), 500)),
        ])
      )[0];
      if (response) {
        return response;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionDeleteMacro(context: MainContext, id: number) {
    try {
      const loadingAlert = { content: "Deleting...", showProgress: true };
      commitAddAlert(context, loadingAlert);
      const response = await macrosDeleteMacro(id, context.state.token);
      if (response) {
        commitRemoveAlert(context, loadingAlert);
        return response;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionDeletePrinterMacro(context: MainContext, id: number) {
    try {
      const response = await macrosDeletePrinterMacro(id, context.state.token);
      if (response) {
        return response;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  // Material
  async actionGetMaterials(context: MainContext) {
    try {
      const response = await materialsReadMaterials({}, context.state.token);
      if (response) {
        commitSetMaterials(context, response);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionUpdateMaterial(
    context: MainContext,
    payload: { id: number; material: IMaterialCreate },
  ) {
    try {
      const loadingAlert = { content: "Saving", showProgress: true };
      commitAddAlert(context, loadingAlert);
      const response = (
        await Promise.all([
          materialsUpdateMaterial(payload.id, payload.material, context.state.token),
          new Promise<void>((resolve, _) => setTimeout(() => resolve(), 500)),
        ])
      )[0];
      commitSetMaterial(context, response);
      commitRemoveAlert(context, loadingAlert);
      commitAddAlert(context, {
        content: "Material successfully updated",
        color: "success",
      });
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionCreateMaterial(context: MainContext, payload: IMaterialCreate) {
    try {
      const loadingAlert = { content: "Saving", showProgress: true };
      commitAddAlert(context, loadingAlert);
      const response = (
        await Promise.all([
          materialsCreateMaterial(payload, context.state.token),
          new Promise<void>((resolve, _) => setTimeout(() => resolve(), 500)),
        ])
      )[0];
      commitSetMaterial(context, response);
      commitRemoveAlert(context, loadingAlert);
      commitAddAlert(context, {
        content: "Material successfully created",
        color: "success",
      });
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionDeleteMaterial(context: MainContext, id: number) {
    try {
      const loadingAlert = { content: "Deleting...", showProgress: true };
      commitAddAlert(context, loadingAlert);
      const response = await materialsDeleteMaterial(id, context.state.token);
      if (response) {
        commitRemoveAlert(context, loadingAlert);
        return response;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  // #region Tags
  async actionGetTags(context: MainContext) {
    try {
      const response = await tagsReadTags({}, context.state.token);
      if (response) {
        commitSetTags(context, response);
        return response;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionGetTag(context: MainContext, id: number) {
    try {
      const response = await tagsReadTag(id, context.state.token);
      if (response) {
        commitSetTag(context, response);
        return response;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionCreateTag(context: MainContext, payload: TagCreate) {
    try {
      const response = await tagsCreateTag(payload, context.state.token);
      if (response) {
        commitSetTag(context, response);
        return response;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionUpdateTag(context: MainContext, payload: { id: number; tag: TagUpdate }) {
    try {
      const response = await tagsUpdateTag(
        payload.id,
        payload.tag,
        context.state.token,
      );
      if (response) {
        commitSetTag(context, response);
        return response;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionDeleteTag(context: MainContext, id: number) {
    try {
      const response = await tagsDeleteTag(id, context.state.token);
      if (response) {
        commitDeleteTag(context, response);
        return response;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  // #endregion
  // Plugin
  async actionGetPluginVersions(context: MainContext) {
    try {
      const response = await utilsGetPluginVersion(context.state.token);
      if (response) {
        commitSetPluginVersions(context, response);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  // Misc
  async actionGetTaskStatus(context: MainContext, payload: TaskIds) {
    try {
      const response = await utilsPostSyncStatus(payload, context.state.token);
      if (response) {
        commitSetTask(context, response);
        return response;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionGetTaskIds(context: MainContext) {
    try {
      const response = await utilsGetAllTaskIdsByOwner(context.state.token);
      if (response) {
        return response;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  // Websocket
  async wsHome(
    context: MainContext,
    payload: { sock: WebSocket; token: string; axes: string[] },
  ) {
    const data = {
      token: payload["token"],
      interface: "client",
      motion: {
        cmd: "home",
        axes: payload["axes"],
      },
    };
    payload["sock"].send(JSON.stringify(data));
  },
  async wsMove(
    context: MainContext,
    payload: { sock: WebSocket; token: string; axes: object },
  ) {
    const data = {
      token: payload["token"],
      interface: "client",
      motion: {
        cmd: "move",
        axes: payload["axes"],
      },
    };
    payload["sock"].send(JSON.stringify(data));
  },
  async wsExtrude(
    context: MainContext,
    payload: { sock: WebSocket; token: string; axes: string[]; value: number },
  ) {
    const data = {
      token: payload["token"],
      interface: "client",
      motion: {
        cmd: "extrude",
        axes: payload["axes"],
        value: payload["value"],
      },
    };
    payload["sock"].send(JSON.stringify(data));
  },
  async wsTemperature(
    context: MainContext,
    payload: { sock: WebSocket; token: string; heater: string; value: number },
  ) {
    const data = {
      token: payload["token"],
      interface: "client",
      temperature: {
        cmd: "temperature",
        heater: payload["heater"],
        value: payload["value"],
      },
    };
    payload["sock"].send(JSON.stringify(data));
  },
  async wsConnectSerial(
    context: MainContext,
    payload: { sock: WebSocket; token: string },
  ) {
    const data = {
      token: payload["token"],
      interface: "client",
      execute: {
        cmd: "connect",
      },
    };
    payload["sock"].send(JSON.stringify(data));
  },
  async wsFiles(
    context: MainContext,
    payload: {
      sock: WebSocket;
      token: string;
      cmd: string;
      file: string;
      content: string | null;
      type: string | null;
    },
  ) {
    const data = {
      token: payload["token"],
      interface: "client",
      files: {
        cmd: payload["cmd"],
        file: payload["file"],
        content: payload["content"],
        loc: "local", // TODO: Support SD cards in future
        type: payload["type"],
      },
    };
    payload["sock"].send(JSON.stringify(data));
  },
  async wsUpdatePlugin(
    context: MainContext,
    payload: {
      sock: WebSocket;
      token: string;
      update_url: string;
      release_tag: string;
    },
  ) {
    const data = {
      token: payload["token"],
      interface: "client",
      update: true,
      update_url: payload["update_url"],
      release_tag: payload["release_tag"],
    };
    payload["sock"].send(JSON.stringify(data));
  },
  async wsSendFileToMachine(
    context: MainContext,
    payload: { sock: WebSocket; token: string; filename: string; url: string },
  ) {
    const data = {
      token: payload["token"],
      interface: "client",
      files: {
        cmd: "upload",
        file: payload["filename"],
        url: payload["url"],
        loc: "local",
        type: "gcode",
      },
    };
    payload["sock"].send(JSON.stringify(data));
  },
  async wsSendFileToMachineAndPrint(
    context: MainContext,
    payload: { sock: WebSocket; token: string; filename: string; url: string },
  ) {
    const data = {
      token: payload["token"],
      interface: "client",
      files: {
        cmd: "upload",
        file: payload["filename"],
        url: payload["url"],
        loc: "local",
        type: "gcode",
        print: true,
      },
    };
    payload["sock"].send(JSON.stringify(data));
  },
  async wsExecute(
    context: MainContext,
    payload: { sock: WebSocket; token: string; cmd: string },
  ) {
    const data = {
      token: payload["token"],
      interface: "client",
      execute: {
        cmd: payload["cmd"],
      },
    };
    payload["sock"].send(JSON.stringify(data));
  },
  async wsGcode(
    context: MainContext,
    payload: { sock: WebSocket; token: string; lines: string[] },
  ) {
    const data = {
      token: payload["token"],
      interface: "client",
      gcode: {
        cmd: "send",
        lines: payload["lines"],
      },
    };
    payload["sock"].send(JSON.stringify(data));
  },
  async wsWebRTC(
    context: MainContext,
    payload: {
      sock: WebSocket;
      token: string;
      auth_key: string;
      cmd: string;
      data: object | null;
    },
  ) {
    const data = {
      token: payload["token"],
      auth_key: payload["auth_key"],
      interface: "client",
      webrtc: payload["cmd"],
      data: payload["data"],
    };
    payload["sock"].send(JSON.stringify(data));
  },
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const { dispatch } = getStoreAccessors<MainState | any, State>("");

export const dispatchCheckApiError = dispatch(actions.actionCheckApiError);
// *** User ***
export const dispatchCheckLoggedIn = dispatch(actions.actionCheckLoggedIn);
export const dispatchGetUserProfile = dispatch(actions.actionGetUserProfile);
export const dispatchCreateNewUser = dispatch(actions.actionCreateNewUser);
export const dispatchLogIn = dispatch(actions.actionLogIn);
export const dispatchLogOut = dispatch(actions.actionLogOut);
export const dispatchUserLogOut = dispatch(actions.actionUserLogOut);
export const dispatchRemoveLogIn = dispatch(actions.actionRemoveLogIn);
export const dispatchRouteLoggedIn = dispatch(actions.actionRouteLoggedIn);
export const dispatchRouteLogOut = dispatch(actions.actionRouteLogOut);
export const dispatchUpdateUserProfile = dispatch(actions.actionUpdateUserProfile);
export const dispatchPasswordRecovery = dispatch(actions.passwordRecovery);
export const dispatchResetPassword = dispatch(actions.resetPassword);
export const dispatchGetUserWebsocket = dispatch(actions.actionGetUserWebsocket);
// *** Alert ***
export const dispatchRemoveAlert = dispatch(actions.removeAlert);
// *** Notification ***
export const dispatchGetNotifications = dispatch(actions.actionGetNotifications);
export const dispatchMarkViewedNotifications = dispatch(
  actions.actionMarkViewedNotifications,
);
export const dispatchDismissNotifications = dispatch(
  actions.actionDismissNotifications,
);
// *** Announcement ***
export const dispatchGetAnnouncements = dispatch(actions.actionGetAnnouncements);
// *** Printer ***
export const dispatchGetPrinters = dispatch(actions.actionGetPrinters);
export const dispatchGetPrinter = dispatch(actions.actionGetPrinter);
export const dispatchUpdatePrinter = dispatch(actions.actionUpdatePrinter);
export const dispatchCreatePrinter = dispatch(actions.actionCreatePrinter);
export const dispatchDeletePrinter = dispatch(actions.actionDeletePrinter);
export const dispatchCreatePrinterTag = dispatch(actions.actionCreatePrinterTag);
export const dispatchDeletePrinterTag = dispatch(actions.actionDeletePrinterTag);
// *** Print job ***
export const dispatchGetPrintJobs = dispatch(actions.actionGetPrintJobs);
export const dispatchGetPrintJobSuccessRate = dispatch(
  actions.actionGetPrintJobSuccessRate,
);
export const dispatchUpdatePrintJob = dispatch(actions.actionUpdatePrintJob);
export const dispatchPostPrintJobZip = dispatch(actions.actionPostPrintJobZip);
export const dispatchGetPrintJobGcode = dispatch(actions.actionGetPrintJobGcode);
export const dispatchGetPrintJobCSV = dispatch(actions.actionGetPrintJobCSV);
export const dispatchGetPrintJobData = dispatch(actions.actionGetPrintJobData);
export const dispatchPostAnalysePrintJob = dispatch(actions.actionPostAnalysePrintJob);
export const dispatchDeletePrintJob = dispatch(actions.actionDeletePrintJob);
// *** CAXTON ***
export const dispatchPostHeatmap = dispatch(actions.actionPostHeatmap);
// *** Error event ***
export const dispatchGetErrorEvents = dispatch(actions.actionGetErrorEvents);
// *** Image item ***
export const dispatchGetImageItems = dispatch(actions.actionGetImageItems);
export const dispatchGetImageItemCount = dispatch(actions.actionGetImageItemCount);
export const dispatchGetImageItemCountByDateRange = dispatch(
  actions.actionGetImageItemCountByDateRange,
);
export const dispatchGetPrintJobMiddleImageItem = dispatch(
  actions.actionGetPrintJobMiddleImageItem,
);
export const dispatchGetPrintJobImageItem = dispatch(
  actions.actionGetPrintJobImageItem,
);
export const dispatchGetPrintJobImageItemCount = dispatch(
  actions.actionGetPrintJobImageItemCount,
);
// *** File ***
export const dispatchGetFiles = dispatch(actions.actionGetFiles);
export const dispatchPostFile = dispatch(actions.actionPostFile);
export const dispatchUpdateFile = dispatch(actions.actionUpdateFile);
export const dispatchDeleteFile = dispatch(actions.actionDeleteFile);
export const dispatchDeleteAllFiles = dispatch(actions.actionDeleteAllFiles);
export const dispatchPostMakeDirectory = dispatch(actions.actionPostMakeDirectory);
// *** Product ***
export const dispatchGetProducts = dispatch(actions.actionGetProducts);
// *** Pricing ***
export const dispatchGetPricings = dispatch(actions.actionGetPricings);
export const dispatchCreateCheckoutSession = dispatch(
  actions.actionCreateCheckoutSession,
);
export const dispatchCreateCustomerPortalSession = dispatch(
  actions.actionCreateCustomerPortalSession,
);
// *** Subscription ***
export const dispatchGetUserSubscriptions = dispatch(
  actions.actionGetUserSubscriptions,
);
// *** File ***
export const dispatchPostGcodeUpload = dispatch(actions.actionPostGcodeUpload);
// *** Macros ***
export const dispatchGetMacros = dispatch(actions.actionGetMacros);
export const dispatchGetMacro = dispatch(actions.actionGetMacro);
export const dispatchUpdateMacro = dispatch(actions.actionUpdateMacro);
export const dispatchPostMacro = dispatch(actions.actionCreateMacro);
export const dispatchDeleteMacro = dispatch(actions.actionDeleteMacro);
export const dispatchPostPrinterMacro = dispatch(actions.actionCreatePrinterMacro);
export const dispatchDeletePrinterMacro = dispatch(actions.actionDeletePrinterMacro);
// *** Material ***
export const dispatchGetMaterials = dispatch(actions.actionGetMaterials);
export const dispatchUpdateMaterial = dispatch(actions.actionUpdateMaterial);
export const dispatchPostMaterial = dispatch(actions.actionCreateMaterial);
export const dispatchDeleteMaterial = dispatch(actions.actionDeleteMaterial);
// *** Tags ***
export const dispatchGetTags = dispatch(actions.actionGetTags);
export const dispatchGetTag = dispatch(actions.actionGetTag);
export const dispatchCreateTag = dispatch(actions.actionCreateTag);
export const dispatchUpdateTag = dispatch(actions.actionUpdateTag);
export const dispatchDeleteTag = dispatch(actions.actionDeleteTag);
// *** Plugin ***
export const dispatchGetPluginVersions = dispatch(actions.actionGetPluginVersions);
// *** Misc ***
export const dispatchGetTaskStatus = dispatch(actions.actionGetTaskStatus);
export const dispatchGetTaskIds = dispatch(actions.actionGetTaskIds);
// *** WebSocket ***
export const dispatchGetSocket = dispatch(actions.actionGetPrinterSocket);
export const dispatchWSHome = dispatch(actions.wsHome);
export const dispatchWSMove = dispatch(actions.wsMove);
export const dispatchWSExtrude = dispatch(actions.wsExtrude);
export const dispatchWSTemperature = dispatch(actions.wsTemperature);
export const dispatchWSFiles = dispatch(actions.wsFiles);
export const dispatchWSExecute = dispatch(actions.wsExecute);
export const dispatchWSGcode = dispatch(actions.wsGcode);
export const dispatchWSUpdatePlugin = dispatch(actions.wsUpdatePlugin);
export const dispatchWSWebRTC = dispatch(actions.wsWebRTC);
export const dispatchWSSendFileToMachine = dispatch(actions.wsSendFileToMachine);
export const dispatchWSSendFileToMachineAndPrint = dispatch(
  actions.wsSendFileToMachineAndPrint,
);
export const dispatchWSConnectSerial = dispatch(actions.wsConnectSerial);
