import { ActionContext } from "vuex";
import { State } from "../state";
import { AdminState } from "./state";
import { getStoreAccessors } from "typesafe-vuex";
import {
  commitAdminSetAnnouncement,
  commitAdminSetAnnouncements,
  commitAdminSetCurrentViewedPrintJob,
  commitAdminSetImageItemCount,
  commitAdminSetImageItems,
  commitAdminSetPricing,
  commitAdminSetPricings,
  commitAdminSetPrinters,
  commitAdminSetPrintJobs,
  commitAdminSetProduct,
  commitAdminSetProducts,
  commitAdminSetSubscription,
  commitAdminSetSubscriptions,
  commitAdminSetUser,
  commitAdminSetUsers,
} from "./mutations";
import { dispatchCheckApiError } from "../main/actions";
import { commitAddAlert, commitRemoveAlert } from "../main/mutations";
import type {
  NotificationCreate,
  NotificationUpdate,
  StripePricingCreate,
  StripePricingUpdate,
  StripeProductCreate,
  StripeProductUpdate,
  StripeSubscriptionUpdate,
  UserCreate,
  UserUpdate,
} from "@/api-generated/api-generated.schemas";
import { printersReadAllPrinters } from "@/api-generated/printers/printers";
import {
  imageItemsCountImageItems,
  imageItemsCountImageItemsDateRange,
  imageItemsReadPrintJobImageItemsAdmin,
} from "@/api-generated/image-items/image-items";
import {
  usersCreateUser,
  usersDeleteUser,
  usersReadUsers,
  usersUpdateUser,
} from "@/api-generated/users/users";
import {
  printJobsReadPrintJobById,
  printJobsReadPrintJobs,
} from "@/api-generated/print-jobs/print-jobs";
import {
  productsCreateStripeProduct,
  productsDeleteStripeProduct,
  productsReadAllStripeProducts,
  productsUpdateStripeProduct,
} from "@/api-generated/products/products";
import {
  pricingsCreateStripePricing,
  pricingsDeleteStripePricing,
  pricingsReadAllStripePricings,
  pricingsUpdateStripePricing,
} from "@/api-generated/pricings/pricings";
import {
  subscriptionsDeleteStripeSubscription,
  subscriptionsReadAllStripeSubscriptions,
  subscriptionsUpdateStripeSubscription,
} from "@/api-generated/subscriptions/subscriptions";
import {
  announcementsCreateAnnouncement,
  announcementsDeleteAnnouncement,
  announcementsReadAllAnnouncements,
  announcementsUpdateAnnouncement,
} from "@/api-generated/announcements/announcements";
import { utilsGetGcpUsage } from "@/api-generated/utils/utils";

type MainContext = ActionContext<AdminState, State>;

export const actions = {
  async actionAdminGetUsers(context: MainContext) {
    try {
      const response = await usersReadUsers({}, context.rootState.main.token);
      if (response) {
        commitAdminSetUsers(context, response);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionAdminUpdateUser(
    context: MainContext,
    payload: { id: number; user: UserUpdate },
  ) {
    try {
      const loadingAlert = { content: "Saving", showProgress: true };
      commitAddAlert(context, loadingAlert);
      const response = (
        await Promise.all([
          usersUpdateUser(payload.id, payload.user, context.rootState.main.token),
          await new Promise<void>((resolve, _) => setTimeout(() => resolve(), 500)),
        ])
      )[0];
      commitAdminSetUser(context, response);
      commitRemoveAlert(context, loadingAlert);
      commitAddAlert(context, {
        content: "User successfully updated",
        color: "success",
      });
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionAdminCreateUser(context: MainContext, payload: UserCreate) {
    try {
      const loadingAlert = { content: "Saving", showProgress: true };
      commitAddAlert(context, loadingAlert);
      const response = (
        await Promise.all([
          usersCreateUser(payload, context.rootState.main.token),
          await new Promise<void>((resolve, _) => setTimeout(() => resolve(), 500)),
        ])
      )[0];
      commitAdminSetUser(context, response);
      commitRemoveAlert(context, loadingAlert);
      commitAddAlert(context, {
        content: "User successfully created",
        color: "success",
      });
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionAdminDeleteUser(context: MainContext, payload: number) {
    try {
      const loadingAlert = { content: "Deleting...", showProgress: true };
      commitAddAlert(context, loadingAlert);
      const response = await usersDeleteUser(payload, context.rootState.main.token);
      if (response) {
        commitRemoveAlert(context, loadingAlert);
        return response;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionAdminGetPrinters(context: MainContext) {
    try {
      const response = await printersReadAllPrinters({}, context.rootState.main.token);
      if (response) {
        commitAdminSetPrinters(context, response);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionAdminGetPrintJobs(
    context: MainContext,
    payload: { limit?: number; sort_by?: string; sort_desc?: boolean },
  ) {
    try {
      const response = await printJobsReadPrintJobs(
        payload,
        context.rootState.main.token,
      );
      if (response) {
        commitAdminSetPrintJobs(context, response);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionAdminGetOnePrintJob(context: MainContext, payload: number) {
    try {
      const response = await printJobsReadPrintJobById(
        payload,
        context.rootState.main.token,
      );
      if (response) {
        commitAdminSetCurrentViewedPrintJob(context, response);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionAdminGetImageItems(
    context: MainContext,
    payload: {
      id: number;
      raw?: boolean;
    },
  ) {
    try {
      const response = await imageItemsReadPrintJobImageItemsAdmin(
        payload.id,
        { raw: payload.raw },
        context.rootState.main.token,
      );
      if (response) {
        commitAdminSetImageItems(context, response);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionAdminGetImageItemCount(context: MainContext) {
    try {
      const response = await imageItemsCountImageItems(context.rootState.main.token);
      if (response !== undefined) {
        commitAdminSetImageItemCount(context, response);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionAdminGetImageItemCountByDateRange(
    context: MainContext,
    payload: {
      start_date: string; // note: this is a str in the format YYYY-MM-DD
      end_date: string; // note: this is a str in the format YYYY-MM-DD
    },
  ) {
    try {
      const response = await imageItemsCountImageItemsDateRange(
        payload,
        context.rootState.main.token,
      );
      if (response !== undefined) {
        commitAdminSetImageItemCount(context, response);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionAdminGetProducts(context: MainContext) {
    try {
      const response = await productsReadAllStripeProducts(
        context.rootState.main.token,
      );
      if (response) {
        commitAdminSetProducts(context, response);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionAdminCreateProduct(context: MainContext, payload: StripeProductCreate) {
    try {
      const loadingAlert = { content: "Saving", showProgress: true };
      commitAddAlert(context, loadingAlert);
      const response = (
        await Promise.all([
          productsCreateStripeProduct(payload, context.rootState.main.token),
          await new Promise<void>((resolve, _) => setTimeout(() => resolve(), 500)),
        ])
      )[0];
      commitAdminSetProduct(context, response);
      commitRemoveAlert(context, loadingAlert);
      commitAddAlert(context, {
        content: "Product successfully created",
        color: "success",
      });
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionAdminUpdateProduct(
    context: MainContext,
    payload: {
      id: number;
      product: StripeProductUpdate;
    },
  ) {
    try {
      const loadingAlert = { content: "Saving", showProgress: true };
      commitAddAlert(context, loadingAlert);
      const response = (
        await Promise.all([
          productsUpdateStripeProduct(
            payload.id,
            payload.product,
            context.rootState.main.token,
          ),
          await new Promise<void>((resolve, _) => setTimeout(() => resolve(), 500)),
        ])
      )[0];
      commitAdminSetProduct(context, response);
      commitRemoveAlert(context, loadingAlert);
      commitAddAlert(context, {
        content: "Product successfully updated",
        color: "success",
      });
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionAdminDeleteProduct(context: MainContext, payload: number) {
    try {
      const loadingAlert = { content: "Deleting...", showProgress: true };
      commitAddAlert(context, loadingAlert);
      const response = await productsDeleteStripeProduct(
        payload,
        context.rootState.main.token,
      );
      if (response) {
        commitRemoveAlert(context, loadingAlert);
        return response;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionAdminGetPricings(context: MainContext) {
    try {
      const response = await pricingsReadAllStripePricings(
        context.rootState.main.token,
      );
      if (response) {
        commitAdminSetPricings(context, response);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionAdminCreatePricing(context: MainContext, payload: StripePricingCreate) {
    try {
      const loadingAlert = { content: "Saving", showProgress: true };
      commitAddAlert(context, loadingAlert);
      const response = (
        await Promise.all([
          pricingsCreateStripePricing(payload, context.rootState.main.token),
          await new Promise<void>((resolve, _) => setTimeout(() => resolve(), 500)),
        ])
      )[0];
      commitAdminSetPricing(context, response);
      commitRemoveAlert(context, loadingAlert);
      commitAddAlert(context, {
        content: "Product successfully created",
        color: "success",
      });
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionAdminUpdatePricing(
    context: MainContext,
    payload: {
      id: number;
      pricing: StripePricingUpdate;
    },
  ) {
    try {
      const loadingAlert = { content: "Saving", showProgress: true };
      commitAddAlert(context, loadingAlert);
      const response = (
        await Promise.all([
          pricingsUpdateStripePricing(
            payload.id,
            payload.pricing,
            context.rootState.main.token,
          ),
          await new Promise<void>((resolve, _) => setTimeout(() => resolve(), 500)),
        ])
      )[0];
      commitAdminSetPricing(context, response);
      commitRemoveAlert(context, loadingAlert);
      commitAddAlert(context, {
        content: "Pricing successfully updated",
        color: "success",
      });
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionAdminDeletePricing(context: MainContext, payload: number) {
    try {
      const loadingAlert = { content: "Deleting...", showProgress: true };
      commitAddAlert(context, loadingAlert);
      const response = await pricingsDeleteStripePricing(
        payload,
        context.rootState.main.token,
      );
      if (response) {
        commitRemoveAlert(context, loadingAlert);
        return response;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionAdminGetSubscriptions(context: MainContext) {
    try {
      const response = await subscriptionsReadAllStripeSubscriptions(
        context.rootState.main.token,
      );
      if (response) {
        commitAdminSetSubscriptions(context, response);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionAdminUpdateSubscription(
    context: MainContext,
    payload: {
      id: number;
      subscription: StripeSubscriptionUpdate;
    },
  ) {
    try {
      const loadingAlert = { content: "Saving", showProgress: true };
      commitAddAlert(context, loadingAlert);
      const response = (
        await Promise.all([
          subscriptionsUpdateStripeSubscription(
            payload.id,
            payload.subscription,
            context.rootState.main.token,
          ),
          await new Promise<void>((resolve, _) => setTimeout(() => resolve(), 500)),
        ])
      )[0];
      commitAdminSetSubscription(context, response);
      commitRemoveAlert(context, loadingAlert);
      commitAddAlert(context, {
        content: "Subscription successfully updated",
        color: "success",
      });
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionAdminDeleteSubscription(context: MainContext, payload: number) {
    try {
      const loadingAlert = { content: "Deleting...", showProgress: true };
      commitAddAlert(context, loadingAlert);
      const response = await subscriptionsDeleteStripeSubscription(
        payload,
        context.rootState.main.token,
      );
      if (response) {
        commitRemoveAlert(context, loadingAlert);
        return response;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionAdminGetAnnouncements(context: MainContext) {
    try {
      const response = await announcementsReadAllAnnouncements(
        {},
        context.rootState.main.token,
      );
      if (response) {
        commitAdminSetAnnouncements(context, response);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionAdminCreateAnnouncement(
    context: MainContext,
    payload: NotificationCreate,
  ) {
    try {
      const loadingAlert = { content: "Saving", showProgress: true };
      commitAddAlert(context, loadingAlert);
      const response = (
        await Promise.all([
          announcementsCreateAnnouncement(payload, context.rootState.main.token),
          await new Promise<void>((resolve, _) => setTimeout(() => resolve(), 500)),
        ])
      )[0];
      commitAdminSetAnnouncement(context, response);
      commitRemoveAlert(context, loadingAlert);
      commitAddAlert(context, {
        content: "Announcement successfully created",
        color: "success",
      });
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionAdminUpdateAnnouncement(
    context: MainContext,
    payload: {
      id: number;
      announcement: NotificationUpdate;
    },
  ) {
    try {
      const loadingAlert = { content: "Saving", showProgress: true };
      commitAddAlert(context, loadingAlert);
      const response = await Promise.all([
        announcementsUpdateAnnouncement(
          payload.id,
          payload.announcement,
          context.rootState.main.token,
        ),
        await new Promise<void>((resolve, _) => setTimeout(() => resolve(), 500)),
      ])[0];
      commitAdminSetAnnouncement(context, response);
      commitRemoveAlert(context, loadingAlert);
      commitAddAlert(context, {
        content: "Subscription successfully updated",
        color: "success",
      });
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionAdminDeleteAnnouncement(context: MainContext, payload: number) {
    try {
      const loadingAlert = { content: "Deleting...", showProgress: true };
      commitAddAlert(context, loadingAlert);
      const response = await announcementsDeleteAnnouncement(
        payload,
        context.rootState.main.token,
      );
      if (response) {
        commitRemoveAlert(context, loadingAlert);
        return response;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionAdminGetGCPUsage(context: MainContext) {
    try {
      const response = await utilsGetGcpUsage(context.rootState.main.token);
      if (response !== undefined) {
        return response;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
};

const { dispatch } = getStoreAccessors<AdminState, State>("");

// *** User ***
export const dispatchAdminCreateUser = dispatch(actions.actionAdminCreateUser);
export const dispatchAdminGetUsers = dispatch(actions.actionAdminGetUsers);
export const dispatchAdminUpdateUser = dispatch(actions.actionAdminUpdateUser);
export const dispatchAdminDeleteUser = dispatch(actions.actionAdminDeleteUser);
// *** Printer ***
export const dispatchAdminGetPrinters = dispatch(actions.actionAdminGetPrinters);
// *** Print Job ***
export const dispatchAdminGetPrintJobs = dispatch(actions.actionAdminGetPrintJobs);
export const dispatchAdminGetOnePrintJob = dispatch(actions.actionAdminGetOnePrintJob);
// *** Image Item ***
export const dispatchAdminGetImageItems = dispatch(actions.actionAdminGetImageItems);
export const dispatchAdminGetImageItemCount = dispatch(
  actions.actionAdminGetImageItemCount,
);
export const dispatchAdminGetImageItemCountByDateRange = dispatch(
  actions.actionAdminGetImageItemCountByDateRange,
);
// *** Product ***
export const dispatchAdminCreateProduct = dispatch(actions.actionAdminCreateProduct);
export const dispatchAdminGetProducts = dispatch(actions.actionAdminGetProducts);
export const dispatchAdminUpdateProduct = dispatch(actions.actionAdminUpdateProduct);
export const dispatchAdminDeleteProduct = dispatch(actions.actionAdminDeleteProduct);
// *** Pricing ***
export const dispatchAdminGetPricings = dispatch(actions.actionAdminGetPricings);
export const dispatchAdminCreatePricing = dispatch(actions.actionAdminCreatePricing);
export const dispatchAdminUpdatePricing = dispatch(actions.actionAdminUpdatePricing);
export const dispatchAdminDeletePricing = dispatch(actions.actionAdminDeletePricing);
// *** Subscription ***
export const dispatchAdminGetSubscriptions = dispatch(
  actions.actionAdminGetSubscriptions,
);
export const dispatchAdminUpdateSubscription = dispatch(
  actions.actionAdminUpdateSubscription,
);
export const dispatchAdminDeleteSubscription = dispatch(
  actions.actionAdminDeleteSubscription,
);
// *** Announcement ***
export const dispatchAdminGetAnnouncements = dispatch(
  actions.actionAdminGetAnnouncements,
);
export const dispatchAdminCreateAnnouncement = dispatch(
  actions.actionAdminCreateAnnouncement,
);
export const dispatchAdminUpdateAnnouncement = dispatch(
  actions.actionAdminUpdateAnnouncement,
);
export const dispatchAdminDeleteAnnouncement = dispatch(
  actions.actionAdminDeleteAnnouncement,
);
// *** Misc ***
export const dispatchAdminGetGCPUsage = dispatch(actions.actionAdminGetGCPUsage);
