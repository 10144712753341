import { AdminState } from "./state";
import { getStoreAccessors } from "typesafe-vuex";
import { State } from "../state";
import {
  AdminTableUser,
  type ImageItemResponse,
  type Notification,
  Printer,
  type PrintJobResponse,
  type StripePricing,
  type StripeProduct,
  type StripeSubscription,
} from "@/api-generated/api-generated.schemas";

export const mutations = {
  adminSetUsers(state: AdminState, payload: AdminTableUser[]) {
    state.adminUsers = payload;
  },
  adminSetUser(state: AdminState, payload: AdminTableUser) {
    const users = state.adminUsers.filter((user) => user.id !== payload.id);
    users.push(payload);
    state.adminUsers = users;
  },
  adminDeleteUser(state: AdminState, payload: AdminTableUser) {
    const userIndex = state.adminUsers.findIndex((user) => user.id === payload.id);
    if (userIndex !== -1) {
      state.adminUsers.splice(userIndex, 1);
    }
  },
  adminSetPrinters(state: AdminState, payload: Printer[]) {
    state.adminPrinters = payload;
  },
  adminSetPrintJobs(state: AdminState, payload: PrintJobResponse[]) {
    state.adminPrintJobs = payload;
  },
  adminSetCurrentViewedPrintJob(state: AdminState, payload: PrintJobResponse) {
    state.adminCurrentlyViewedPrintJob = payload;
  },
  adminDeleteCurrentViewedPrintJob(state: AdminState) {
    state.adminCurrentlyViewedPrintJob = null;
  },
  adminSetImageItems(state: AdminState, payload: ImageItemResponse[]) {
    state.adminImageItems = payload;
  },
  adminSetImageItemCount(state: AdminState, payload: number) {
    state.adminImageItemCount = payload;
  },
  adminSetProducts(state: AdminState, payload: StripeProduct[]) {
    state.adminStripeProducts = payload;
  },
  adminSetProduct(state: AdminState, payload: StripeProduct) {
    const products = state.adminStripeProducts.filter(
      (product) => product.id !== payload.id,
    );
    products.push(payload);
    state.adminStripeProducts = products;
  },
  adminDeleteProduct(state: AdminState, payload: StripeProduct) {
    const productIndex = state.adminStripeProducts.findIndex(
      (product) => product.id === payload.id,
    );
    if (productIndex !== -1) {
      state.adminStripeProducts.splice(productIndex, 1);
    }
  },
  adminSetPricings(state: AdminState, payload: StripePricing[]) {
    state.adminStripePricings = payload;
  },
  adminSetPricing(state: AdminState, payload: StripePricing) {
    const pricings = state.adminStripePricings.filter(
      (pricing) => pricing.id !== payload.id,
    );
    pricings.push(payload);
    state.adminStripePricings = pricings;
  },
  adminDeletePricing(state: AdminState, payload: StripePricing) {
    const pricingIndex = state.adminStripePricings.findIndex(
      (pricing) => pricing.id === payload.id,
    );
    if (pricingIndex !== -1) {
      state.adminStripePricings.splice(pricingIndex, 1);
    }
  },
  adminSetSubscriptions(state: AdminState, payload: StripeSubscription[]) {
    state.adminStripeSubscriptions = payload;
  },
  adminSetSubscription(state: AdminState, payload: StripeSubscription) {
    const subscriptions = state.adminStripeSubscriptions.filter(
      (subscription) => subscription.id !== payload.id,
    );
    subscriptions.push(payload);
    state.adminStripeSubscriptions = subscriptions;
  },
  adminDeleteSubscription(state: AdminState, payload: StripeSubscription) {
    const subscriptionIndex = state.adminStripeSubscriptions.findIndex(
      (subscription) => subscription.id === payload.id,
    );
    if (subscriptionIndex !== -1) {
      state.adminStripeSubscriptions.splice(subscriptionIndex, 1);
    }
  },
  adminSetAnnouncements(state: AdminState, payload: Notification[]) {
    state.adminAnnouncements = payload;
  },
  adminSetAnnouncement(state: AdminState, payload: Notification) {
    const announcements = state.adminAnnouncements.filter(
      (announcement) => announcement.id !== payload.id,
    );
    announcements.push(payload);
    state.adminAnnouncements = announcements;
  },
  adminDeleteAnnouncement(state: AdminState, payload: Notification) {
    const announcementIndex = state.adminAnnouncements.findIndex(
      (announcement) => announcement.id === payload.id,
    );
    if (announcementIndex !== -1) {
      state.adminAnnouncements.splice(announcementIndex, 1);
    }
  },
};

const { commit } = getStoreAccessors<AdminState, State>("");

export const commitAdminSetUser = commit(mutations.adminSetUser);
export const commitAdminSetUsers = commit(mutations.adminSetUsers);
export const commitAdminDeleteUser = commit(mutations.adminDeleteUser);
export const commitAdminSetPrinters = commit(mutations.adminSetPrinters);
export const commitAdminSetPrintJobs = commit(mutations.adminSetPrintJobs);
export const commitAdminSetCurrentViewedPrintJob = commit(
  mutations.adminSetCurrentViewedPrintJob,
);
export const commitAdminDeleteCurrentViewedPrintJob = commit(
  mutations.adminDeleteCurrentViewedPrintJob,
);
export const commitAdminSetImageItems = commit(mutations.adminSetImageItems);
export const commitAdminSetImageItemCount = commit(mutations.adminSetImageItemCount);
export const commitAdminSetProduct = commit(mutations.adminSetProduct);
export const commitAdminSetProducts = commit(mutations.adminSetProducts);
export const commitAdminDeleteProduct = commit(mutations.adminDeleteProduct);
export const commitAdminSetPricings = commit(mutations.adminSetPricings);
export const commitAdminSetPricing = commit(mutations.adminSetPricing);
export const commitAdminDeletePricing = commit(mutations.adminDeletePricing);
export const commitAdminSetSubscriptions = commit(mutations.adminSetSubscriptions);
export const commitAdminSetSubscription = commit(mutations.adminSetSubscription);
export const commitAdminDeleteSubscription = commit(mutations.adminDeleteSubscription);
export const commitAdminSetAnnouncements = commit(mutations.adminSetAnnouncements);
export const commitAdminSetAnnouncement = commit(mutations.adminSetAnnouncement);
export const commitAdminDeleteAnnouncement = commit(mutations.adminDeleteAnnouncement);
