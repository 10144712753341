// custom-instance.ts
import Axios, { AxiosRequestConfig } from "axios";
import { apiUrl } from "@/env";

export const AXIOS_INSTANCE = Axios.create({ baseURL: apiUrl });

/**
 * Generate authorization headers for API requests.
 * @param token - JWT token for authentication.
 * @returns Axios request configuration with authorization headers.
 */
export const authHeaders = (token: string): AxiosRequestConfig => {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
};

export const apiAxiosInstance = <T>(
  config: AxiosRequestConfig,
  authToken?: string | AxiosRequestConfig,
): Promise<T> => {
  const source = Axios.CancelToken.source();
  const promise = AXIOS_INSTANCE({
    ...config,
    ...(typeof authToken === "string" ? authHeaders(authToken) : authToken ?? {}),
    cancelToken: source.token,
  }).then(({ data }) => data);
  // @ts-expect-error description
  promise.cancel = () => {
    source.cancel("Query was cancelled");
  };
  return promise;
};
