import { IAppAlert, INotification, IPrinterSocket, IUserSocket } from "@/interfaces";
import { MainState } from "./state";
import { getStoreAccessors } from "typesafe-vuex";
import { State } from "../state";
import { wsUrl } from "@/env";
import type {
  Eventlog,
  File,
  GetPluginVersionResponse,
  ImageItem,
  MacroWithPrinterId,
  Material,
  Notification,
  Printer,
  PrintJob,
  StripePricing,
  StripeProduct,
  StripeSubscription,
  Tag,
  TaskStatus,
  User,
} from "@/api-generated/api-generated.schemas";

export const mutations = {
  setOriginalURL(state: MainState, payload: string) {
    state.originalURL = payload;
  },
  setToken(state: MainState, payload: string) {
    state.token = payload;
  },
  setLoggedIn(state: MainState, payload: boolean) {
    state.isLoggedIn = payload;
  },
  setLogInError(state: MainState, payload: boolean) {
    state.logInError = payload;
  },
  setSignUpError(state: MainState, payload: boolean) {
    state.signUpError = payload;
  },
  setUserProfile(state: MainState, payload: User) {
    state.userProfile = payload;
  },
  setDashboardMiniDrawer(state: MainState, payload: boolean) {
    state.dashboardMiniDrawer = payload;
  },
  setDashboardShowDrawer(state: MainState, payload: boolean) {
    state.dashboardShowDrawer = payload;
  },
  addAlert(state: MainState, payload: IAppAlert) {
    state.alerts.push(payload);
  },
  removeAlert(state: MainState, payload: IAppAlert) {
    state.alerts = state.alerts.filter((alert) => alert !== payload);
  },
  setPrinters(state: MainState, payload: Printer[]) {
    state.printers = payload;
  },
  updatePrinterState(state: MainState, payload: { id: number; printerState: string }) {
    // find the printer with the given id and update its state
    const index = state.printers.findIndex((printer) => printer.id === payload.id);
    if (index !== -1) {
      // create a new printer object with the updated state
      const updatedPrinter = {
        ...state.printers[index],
        state: payload.printerState.toLowerCase(),
      };
      // replace the old printer with the updated printer in the printers array
      state.printers.splice(index, 1, updatedPrinter);
    }
  },
  async setPrinterSockets(state: MainState) {
    // Function to connect to a WebSocket for a printer
    const connectWebSocket = async (printer: Printer) => {
      // Check if there's already a socket for this printer in state
      const printerSocket = state.printerSockets.find(
        (printerSocket: IPrinterSocket) =>
          printerSocket && printerSocket.id === printer.id,
      );

      // If there's no socket or the existing socket is not open
      if (!printerSocket || printerSocket.socket.readyState !== WebSocket.OPEN) {
        // Create a promise to resolve the WebSocket connection
        const socketPromise = new Promise<WebSocket>((resolve) => {
          // Check if there's an existing open socket for this printer
          const existingSocket = printerSocket?.socket;

          // If an existing open socket is available, resolve with it
          if (existingSocket && existingSocket.readyState === WebSocket.OPEN) {
            resolve(existingSocket);
          } else {
            // Otherwise, create a new WebSocket connection
            const socket = new WebSocket(
              `${wsUrl}/api/v1/ws/client?token=${printer.auth_key}`,
            );

            // Listen for the WebSocket to open and resolve with it
            socket.addEventListener("open", () => {
              resolve(socket);
            });
          }
        });

        // Create a new printer socket with the resolved WebSocket
        const newPrinterSocket: IPrinterSocket = {
          id: printer.id,
          auth_key: printer.auth_key,
          socket: await socketPromise,
        };

        // Return the new printer socket
        return newPrinterSocket;
      }

      // If there's an open socket for this printer, return it
      return printerSocket;
    };

    // Create an array of promises to connect to WebSockets for all printers
    const printerSocketPromises = state.printers.map(connectWebSocket);

    // Resolve all the promises concurrently and update the printer sockets in state
    const resolvedPrinterSockets = await Promise.all(printerSocketPromises);
    state.printerSockets = resolvedPrinterSockets;
  },
  async setPrinterSocket(state: MainState, printer) {
    const connectWebSocket = async (printer) => {
      // Check if there's already a socket for this printer in state
      const printerSocket = state.printerSockets.find(
        (printerSocket: IPrinterSocket) =>
          printerSocket && printerSocket.id === printer.id,
      );

      // If there's no socket or the existing socket is not open
      if (!printerSocket || printerSocket.socket.readyState !== WebSocket.OPEN) {
        // Create a promise to resolve the WebSocket connection
        const socketPromise = new Promise<WebSocket>((resolve) => {
          // Check if there's an existing open socket for this printer
          const existingSocket = printerSocket?.socket;

          // If an existing open socket is available, resolve with it
          if (existingSocket && existingSocket.readyState === WebSocket.OPEN) {
            resolve(existingSocket);
          } else {
            // Otherwise, create a new WebSocket connection
            const socket = new WebSocket(
              `${wsUrl}/api/v1/ws/client?token=${printer.auth_key}`,
            );

            // Listen for the WebSocket to open and resolve with it
            socket.addEventListener("open", () => {
              resolve(socket);
            });
          }
        });

        // Create a new printer socket with the resolved WebSocket
        const newPrinterSocket: IPrinterSocket = {
          id: printer.id,
          auth_key: printer.auth_key,
          socket: await socketPromise,
        };

        // Return the new printer socket
        return newPrinterSocket;
      }

      // If there's an open socket for this printer, return it
      return printerSocket;
    };
    try {
      // use connectWebSocket to connect to the WebSocket for the printer
      const printerSocket = await connectWebSocket(printer);
      // drop the existing printer socket from state and add the new one
      state.printerSockets = state.printerSockets.filter(
        (printerSocket: IPrinterSocket) =>
          printerSocket && printerSocket.id !== printer.id,
      );
      state.printerSockets[printer.id] = printerSocket as IPrinterSocket;
    } catch (error) {
      console.error(
        "Error setting printer socket: ",
        error,
        "with print sockets: ",
        state.printerSockets,
        "with printer: ",
        printer,
      );
    }
  },
  async setUserSocket(state: MainState, auth_key: string) {
    // Function to connect to a WebSocket for a user
    const connectWebSocket = async () => {
      // Check if there's already a socket for this printer in state
      const userSocket = state.userSocket;

      // If there's no socket or the existing socket is not open
      if (!userSocket || userSocket.socket.readyState !== WebSocket.OPEN) {
        // Create a promise to resolve the WebSocket connection
        const socketPromise = new Promise<WebSocket>((resolve) => {
          // Check if there's an existing open socket for this printer
          let existingSocket: WebSocket | undefined;
          if (userSocket) {
            existingSocket = userSocket.socket;
          }

          // If an existing open socket is available, resolve with it
          if (existingSocket && existingSocket.readyState === WebSocket.OPEN) {
            resolve(existingSocket);
          } else {
            // Otherwise, create a new WebSocket connection
            //get token from local storage
            const socket = new WebSocket(`
            ${wsUrl}/api/v1/ws/user?token=${auth_key}
            `);

            // Listen for the WebSocket to open and resolve with it
            socket.addEventListener("open", () => {
              resolve(socket);
            });
          }
        });

        // Create a new user socket with the resolved WebSocket
        const token = localStorage.getItem("token");
        const newUserSocket: IUserSocket = {
          auth_key: token || "",
          socket: await socketPromise,
        };

        // Return the new printer socket
        return newUserSocket;
      }

      // If there's an open socket for this printer, return it
      return userSocket;
    };

    // Await the user socket
    const userSocket = await connectWebSocket();

    // Set the user socket in state
    state.userSocket = userSocket;

    state.userSocket.socket.onmessage = (event) => {
      const message = JSON.parse(event.data);
      // If the message has notification type_id field, add a notification
      if (message.type_id) {
        this.sendNotification(message);
        state.notifications.push(message);
      }
    };
  },
  setPrintJobs(state: MainState, payload: PrintJob[]) {
    state.printJobs = payload;
  },
  setPrintJob(state: MainState, payload: PrintJob) {
    const printJobs = state.printJobs.filter((printJob) => printJob.id !== payload.id);
    printJobs.push(payload);
    state.printJobs = printJobs;
  },
  deletePrintJob(state: MainState, payload: PrintJob) {
    const printJobIndex = state.printJobs.findIndex(
      (printJob) => printJob.id === payload.id,
    );
    state.printJobs.splice(printJobIndex, 1);
  },
  setImageItems(state: MainState, payload: ImageItem[]) {
    state.imageItems = payload;
  },
  setImageItemCount(state: MainState, payload: number) {
    state.imageItemCount = payload;
  },
  setPrinter(state: MainState, payload: Printer) {
    const index = state.printers.findIndex((printer) => printer.id === payload.id);
    if (index !== -1) {
      // If the printer exists, update it
      state.printers[index] = payload;
    } else {
      // If the printer doesn't exist, add it to the end of the list
      state.printers.push(payload);
    }
  },
  deletePrinter(state: MainState, payload: Printer) {
    const printerIndex = state.printers.findIndex(
      (printer) => printer.id === payload.id,
    );
    state.printers.splice(printerIndex, 1);
  },
  setFiles(state: MainState, payload: File[]) {
    state.files = payload;
  },
  setFile(state: MainState, payload: File) {
    const files = state.files.filter((file) => file.id !== payload.id);
    files.push(payload);
    state.files = files;
  },
  deleteFile(state: MainState, payload: File) {
    const fileIndex = state.files.findIndex((file) => file.id === payload.id);
    state.files.splice(fileIndex, 1);
  },
  deleteAllFiles(state: MainState) {
    state.files = [];
  },
  setErrorEvents(state: MainState, payload: Eventlog[]) {
    state.errorEvents = payload;
  },
  addNotification(state: MainState, payload: INotification) {
    state.notifications.push(payload);
  },
  // Send browser notification
  sendNotification(data) {
    const permission = Notification.permission;
    if (permission === "granted") {
      showNotification();
    } else if (permission === "default") {
      requestAndShowPermission();
    } else {
      console.warn("Notification permission denied");
    }

    function showNotification() {
      if (document.visibilityState === "visible") {
        return;
      }
      const title = data["subject"];
      const icon = data["link"];
      const body = "Message to be displayed";
      const notification = new Notification(title, { body, icon });
      notification.onclick = () => {
        notification.close();
        window.parent.focus();
      };
    }

    function requestAndShowPermission() {
      Notification.requestPermission(function (permission) {
        if (permission === "granted") {
          showNotification();
        }
      });
    }
  },
  setNotifications(state: MainState, notifications: Notification[]) {
    state.notifications = notifications;
  },
  dismissNotification(state: MainState, payload: Notification) {
    const notificationIndex = state.notifications.findIndex(
      (notification) => notification.id === payload.id,
    );
    state.notifications.splice(notificationIndex, 1);
  },
  dismissAllNotifications(state: MainState) {
    state.notifications = [];
  },
  removeNotifications(state: MainState, notifications: Notification[]) {
    state.notifications = state.notifications.filter(
      (notification) => !notifications.includes(notification),
    );
  },
  setAnnouncements(state: MainState, announcements: Notification[]) {
    state.announcements = announcements;
  },
  setProducts(state: MainState, stripeProducts: StripeProduct[]) {
    state.stripeProducts = stripeProducts;
  },
  setPricings(state: MainState, stripePricings: StripePricing[]) {
    state.stripePricings = stripePricings;
  },
  setUserSubscriptions(
    state: MainState,
    userStripeSubscriptions: StripeSubscription[],
  ) {
    state.userStripeSubscriptions = userStripeSubscriptions;
  },
  // *** Macros ***
  setMacros(state: MainState, macros: MacroWithPrinterId[]) {
    state.macros = macros;
  },
  setMacro(state: MainState, payload: MacroWithPrinterId) {
    const macros = state.macros.filter((macro) => macro.id !== payload.id);
    macros.push(payload);
    state.macros = macros;
  },
  deleteMacro(state: MainState, payload: MacroWithPrinterId) {
    const macroIndex = state.macros.findIndex((macro) => macro.id === payload.id);
    state.macros.splice(macroIndex, 1);
  },
  // *** Materials ***
  setMaterials(state: MainState, materials: Material[]) {
    state.materials = materials;
  },
  setMaterial(state: MainState, payload: Material) {
    const materials = state.materials.filter((material) => material.id !== payload.id);
    materials.push(payload);
    state.materials = materials;
  },
  deleteMaterial(state: MainState, payload: Material) {
    const materialIndex = state.materials.findIndex(
      (material) => material.id === payload.id,
    );
    state.materials.splice(materialIndex, 1);
  },
  // # region Tags
  setTags(state: MainState, tags: Tag[]) {
    state.tags = tags;
  },
  setTag(state: MainState, payload: Tag) {
    const index = state.tags.findIndex((tag) => tag.id === payload.id);
    if (index !== -1) {
      state.tags.splice(index, 1, payload);
    } else {
      state.tags.push(payload);
    }
  },
  deleteTag(state: MainState, payload: Tag) {
    const tagIndex = state.tags.findIndex((tag) => tag.id === payload.id);
    state.tags.splice(tagIndex, 1);
  },
  // # endregion
  // *** Plugins ***
  setPluginVersions(state: MainState, pluginVersions: GetPluginVersionResponse) {
    state.pluginVersions = pluginVersions;
  },
  // *** Misc ***
  addTask(state: MainState, payload: TaskStatus) {
    state.tasks.push(payload);
  },
  removeTask(state: MainState, payload: TaskStatus) {
    state.tasks = state.tasks.filter((t) => t !== payload);
  },
  removeTaskByID(state: MainState, task_id: string) {
    state.tasks = state.tasks.filter((task) => task.task_id !== task_id);
  },
  setTask(state: MainState, payload: TaskStatus[]) {
    // todo matt doesn't understand what this does
    for (const task of payload) {
      const tasks = state.tasks.filter((t) => t.task_id !== task.task_id);
      tasks.push(task);
      state.tasks = tasks;
    }
  },
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const { commit } = getStoreAccessors<MainState | any, State>("");

export const commitSetDashboardMiniDrawer = commit(mutations.setDashboardMiniDrawer);
export const commitSetDashboardShowDrawer = commit(mutations.setDashboardShowDrawer);
// *** User ***
export const commitSetLoggedIn = commit(mutations.setLoggedIn);
export const commitSetLogInError = commit(mutations.setLogInError);
export const commitSetSignUpError = commit(mutations.setSignUpError);
export const commitSetOriginalURL = commit(mutations.setOriginalURL);
export const commitSetToken = commit(mutations.setToken);
export const commitSetUserProfile = commit(mutations.setUserProfile);
// *** Alert ***
export const commitAddAlert = commit(mutations.addAlert);
export const commitRemoveAlert = commit(mutations.removeAlert);
// *** Notification ***
export const commitSetNotifications = commit(mutations.setNotifications);
export const commitRemoveNotifications = commit(mutations.removeNotifications);
export const commitDismissNotification = commit(mutations.dismissNotification);
export const commitDismissAllNotifications = commit(mutations.dismissAllNotifications);
export const commitSendNotification = commit(mutations.sendNotification);
export const commitAddNotification = commit(mutations.addNotification);
// *** Announcements ***
export const commitSetAnnouncements = commit(mutations.setAnnouncements);
// *** Printer ***
export const commitSetPrinters = commit(mutations.setPrinters);
export const commitSetPrinterSockets = async (state: MainState) => {
  await mutations.setPrinterSockets(state);
};
export const commitSetPrinterSocket = async (state: MainState, printer) => {
  await mutations.setPrinterSocket(state, printer);
};
export const commitSetUserSocket = async (state: MainState, auth_key) => {
  await mutations.setUserSocket(state, auth_key);
};
export const commitSetPrinter = commit(mutations.setPrinter);
export const commitDeletePrinter = commit(mutations.deletePrinter);
export const commitUpdatePrinterState = commit(mutations.updatePrinterState);
// *** Print job ***
export const commitSetPrintJobs = commit(mutations.setPrintJobs);
export const commitSetPrintJob = commit(mutations.setPrintJob);
export const commitDeletePrintJob = commit(mutations.deletePrintJob);
// *** Error event ***
export const commitSetErrorEvents = commit(mutations.setErrorEvents);
// *** Image item ***
export const commitSetImageItems = commit(mutations.setImageItems);
export const commitSetImageItemCount = commit(mutations.setImageItemCount);
// *** File ***
export const commitSetFiles = commit(mutations.setFiles);
export const commitSetFile = commit(mutations.setFile);
export const commitDeleteFile = commit(mutations.deleteFile);
export const commitDeleteAllFiles = commit(mutations.deleteAllFiles);
// *** Products ***
export const commitSetProducts = commit(mutations.setProducts);
// *** Pricings ***
export const commitSetPricings = commit(mutations.setPricings);
// *** Subscriptions ***
export const commitSetUserSubscriptions = commit(mutations.setUserSubscriptions);
// *** Macros ***
export const commitSetMacros = commit(mutations.setMacros);
export const commitSetMacro = commit(mutations.setMacro);
export const commitDeleteMacro = commit(mutations.deleteMacro);
// *** Materials ***
export const commitSetMaterials = commit(mutations.setMaterials);
export const commitSetMaterial = commit(mutations.setMaterial);
export const commitDeleteMaterial = commit(mutations.deleteMaterial);
// # region Tags
export const commitSetTags = commit(mutations.setTags);
export const commitSetTag = commit(mutations.setTag);
export const commitDeleteTag = commit(mutations.deleteTag);
// # endregion
// *** Plugins ***
export const commitSetPluginVersions = commit(mutations.setPluginVersions);
// *** Misc ***
export const commitAddTask = commit(mutations.addTask);
export const commitRemoveTask = commit(mutations.removeTask);
export const commitRemoveTaskByID = commit(mutations.removeTaskByID);
export const commitSetTask = commit(mutations.setTask);
